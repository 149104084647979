import React from "react";
import VerticleSauced from '../assets/VerticleSauced.png';
import { useNavigate } from 'react-router-dom';

function Landing() {
  const navigate = useNavigate(); // useNavigate must be called inside a component

  const goToHome = () => {
    navigate('/home'); // This will change the route to /home
  };
  return (
    <div className="bg-black h-screen px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <img src={VerticleSauced} style={{ marginTop: '5px', width: 'auto', height: 'auto' }} alt="Sauced PR" />
        <h1 className="text-white mt-5">Welcome to sauced - a brand new wing shop located on the west coast of Puerto Rico.  We specialize in cooked to perfection wings with unique and exciting new flavors. </h1>
        <button
          type="button"
          className="rounded-full bg-red-600 px-3.5 py-2 mt-16 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={goToHome}
        >
          View Menu
        </button>
      </div>
    </div>
  );
}

export default Landing;
