import React from "react";
import { FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-screen p-5 bg-black top-0 left-0 right-0">
      <div className="container mt-24 lg:mt-6 mx-auto p-5 ">
        <div className="px-6 lg:mx-5 lg:mt-6">
          <h1 className="text-white font-bold text-2xl lg:text-3xl text-left">
            Quick <span className="text-red-500">Links</span>
          </h1>
          <hr className="my-6"></hr>
          <div className="max-w-[1240px] mx-auto py-10 px-4 grid lg:grid-cols-3 gap-8">
            <div>
              <p className="py-4 text-white">
                Prepare yourself for a <span className="text-red-500">taste</span> explosion right in your {" "}
                <span className="text-red-500">mouth</span> .  It's like getting kicked in the teeth in the best way possible! You're going to love it.
              </p>
              <div className="flex justify-between md:w-[75%] my-6">
                <a href="https://wa.me/234">
                  {" "}
                  <FaWhatsapp
                    size={30}
                    className="hover:scale-110 text-white duration-300 hover:text-red-500"
                  />{" "}
                </a>
                <a href="https://instagram.com/chefkim_">
                  {" "}
                  <FaInstagram
                    size={30}
                    className="hover:scale-110 text-white duration-300 hover:text-red-500"
                  />{" "}
                </a>
                <a href="https://twitter.com/adarhx">
                  <FaTwitter
                    size={30}
                    className="hover:scale-110 text-white duration-300 hover:text-red-500"
                  />
                </a>
                <a href="mailto:chefkimkitchenn@gmail.com">
                  <FiMail
                    size={30}
                    className="hover:scale-110 text-white duration-300 hover:text-red-500"
                  />
                </a>
              </div>
              <h3 className="text-xs text-white">Built and maintained by MMEG LLC</h3>
            </div>
            <div className="lg:col-span-2 flex justify-between sm:mx-10 lg:mx-14  mt-6">
              <div>
                <h6 className="font-medium text-red-500">Links</h6>
                <ul>
                  <li className="py-2 text-sm text-white hover:text-red-600 cursor-pointer">
                    {" "}
                    <Link to="/">Home</Link>
                  </li>
                  <li className="py-2 text-sm text-white hover:text-red-600 cursor-pointer">
                    <Link to="/menu">Menu</Link>
                  </li>
                  <li className="py-2 text-sm text-white hover:text-red-600 cursor-pointer">
                    <Link to="/categories">Categories</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="font-medium text-red-500">Support</h6>
                <ul>
                  <li className="py-2 text-white text-sm ">
                    About
                  </li>
                  <li className="py-2 text-white text-sm ">
                    FAQ
                  </li>
                  <li className="py-2 text-white text-sm ">
                    Ts&Cs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
