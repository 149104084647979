import dryranch from '../assets/menuitems/dryranch.jpg';
import signature from "../assets/menuitems/signature.jpg";
import honeyhab from "../assets/menuitems/honeyhab.jpg";
import death from "../assets/menuitems/death.jpg";

export const food = [
  {
    id: 1,
    name: 'Get Sauced Signature Wings',
    category: 'signature wings',
    image:
      signature,
    price: '12.98',
  },
  {
    id: 2,
    name: 'Dry Ranch Rub',
    category: 'signature wings',
    image:
      dryranch,
    price: '30.7',
  },
  {
    id: 3,
    name: 'Honey Habenero',
    category: 'signature wings',
    image:
      honeyhab,
    price: '40.08',
  },
  {
    id: 4,
    name: 'Pure Death',
    category: 'signature wings',
    image:
      death,
    price: '50.99',
  },
];

export const categories = [
  {
    id: 1,
    name: 'signature wings',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/FastFood_BrowseHome@3x.png',
  },
  {
    id: 2,
    name: 'Rotating Sauces',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Pizza_BrowseHome@3x.png',
  },
  {
    id: 3,
    name: 'Sides',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Wings_BrowseHome@3x.png',
  },
  {
    id: 4,
    name: 'Drinks',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Indian_BrowseHome@3x.png',
  },
  {
    id: 5,
    name: 'Lunch Specials',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Deals_BrowseHome@3x.png',
  },
  {
    id: 6,
    name: 'Fiesta Packs',
    image:
      'https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/RestoRewards_BrowseHome@3x.png',
  },
];
